<template>
<div class="container">
  <div>
    <div class="top" id="bloc-0">
      <voice-header></voice-header>
    </div>
    <div class="pay_container">
        <svg class="pay_icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="#00b894" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
            <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
            <polyline points="22 4 12 14.01 9 11.01"></polyline>
        </svg>
        <h1>{{ $t('pricing.pay_succ') }}</h1>
        <p>{{ $t('pricing.pay_succ_desc') }}</p>
        <a :href="home_href" class="pay_succ_btn">{{ $t('pricing.pay_succ_btntext') }}</a>
    </div>
    <!-- form @submit.prevent="createPortalSession">
      <input type="hidden" id="session-id" name="session_id" :value="sessionId">
      <button id="checkout-and-portal-button" type="submit">Manage your billing information</button>
    </form -->
  </div>
</div>
</template>

<script>
import axios from 'axios'

import VoiceHeader from '../components/VoiceHeader.vue';

export default {
  name: 'pay_success',
  data() {
    return {
      sessionId: this.$route.query.session_id || '', // 从路由查询参数获取 session_id
      home_href: '/',
    }
  },
  head() {
    return {
      'title': this.$i18n.t('txt2voice.title'),
      'keywords': this.$i18n.t('txt2voice.keywords'),
      'description': this.$i18n.t('txt2voice.description'),
    }
  },
  components: {
    VoiceHeader
  },
  asyncData: function ({ store, route }) {
    // console.log('home state count:' + store.state.count)
    return store.dispatch("fetchData")
  },
  methods: {
    async createPortalSession() {
      var host = this.$i18n.t('host')
      var uri = host + '/lapi/cportalsess'
      try {
        const formData = new FormData()
        formData.append('session_id', this.sessionId)
	const headers = {
	  'Content-Type': 'multipart/form-data'
	}
        const res = await axios.post(uri, formData, headers)
	if (res.data.ret === 0){
	  window.open(res.data.url)
	} else {
	  console.log('msg:' + res.data.msg)
	  alert('An error occurred, please mail us! Thanks!')
	}
      } catch (error) {
        console.error('Error creating portal session:', error)
	alert('An error occurred, please mail us! Thanks!')
      }
    }
  },
  mounted () {
    if (this.$store.state.lang !== 'en') {
      this.home_href = '/' + this.$store.state.lang  
    }
  }
}
</script>

<style>
</style>
